@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

input:focus {
    border: none;
}

.heSearch-searchBox .react-autosuggest__container {
    z-index: auto;
}
.heSearch-searchIcon {
    z-index: auto;
}

.changeLocation {
    padding-left: 10px;
    text-decoration: none;
    color: #333333;
    height: 1em;
    font-size: .75em;
    a {
        color: grey;
        text-decoration: none;
        &:hover {
            color: #777777;
        }
    }
    cursor: pointer;
    &:hover {
        color: darkgrey;
    }
}

table {
    margin-bottom: 0;
}

td:first-child,
th:first-child {
  padding-left: .5em;
}

td:last-child,
th:last-child {
  padding-right: .5em;
}

.footer-link {
    color: #777777;
}

.startscreen-video {
    z-index: 0;
    pointer-events: none;
    min-height: 100%;
}

.geo-item {
    :hover {
        background: #aaa;
    }
}
.narPopup_pinned {
    pointer-events: all;
    // border: 1px solid black;
}
.narPopup_floating {
    pointer-events: none;
}
.app {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
}